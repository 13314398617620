* {
  box-sizing: border-box;
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
}

body,
#root {
  height: calc(100vh - 64px);
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover,
a:active,
a:focus,
a:visited {
  color: inherit;
}

.width_container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  font-size: 1.375rem;
  font-weight: 400;
  display: inline-block;
}

p {
  line-height: 1.6;
}
